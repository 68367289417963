import { createStyles } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import flagEn from "public/flag_en.png";
import flagVn from "public/flag_vn.png";

const useStyles = createStyles(() => ({
  language: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  languageItem: {
    marginRight: "5px",
    cursor: "pointer",
    width: 27,
    height: 18,
  },
  languageNotActive: {
    filter: "grayscale(100%)",
  },
}));

export default function Language() {
  const { classes } = useStyles();

  const {
    locale,
    // pathname,
    asPath,
    // query: { slug },
  } = useRouter();

  return (
    <div className={classes.language}>
      <Link scroll={false} href={asPath} locale="vi">
        <a>
          <div
            className={
              classes.languageItem +
              " " +
              `${locale !== "vi" ? classes.languageNotActive : ""}`
            }
          >
            <Image
              src={flagVn}
              alt="Ngôn ngữ Việt"
              width={27}
              height={18}
              objectFit={"cover"}
            />
          </div>
        </a>
      </Link>
      <Link scroll={false} href={asPath} locale="en">
        <a>
          <div
            className={
              classes.languageItem +
              " " +
              `${locale !== "en" ? classes.languageNotActive : ""}`
            }
          >
            <Image
              src={flagEn}
              alt="English"
              width={27}
              height={18}
              objectFit={"cover"}
            />
          </div>
        </a>
      </Link>
    </div>
  );
}
