const dmchietkhauKeys = {
  all: () => [
    {
      scope: "dmchietkhau",
      type: "all",
    },
  ],
  list: (ma_vt) => [
    {
      scope: "dmchietkhau",
      type: "list",
      ma_vt,
    },
  ],

  detail: (ma_tt) => [{ scope: "dmchietkhau", type: "detail", ma_tt }],
};

export default dmchietkhauKeys;
