/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  NavLink,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import Link from "next/link";
import React from "react";

import useAuth from "@/hooks/useAuth";
import useTrans from "@/hooks/useTrans";
import useUserInfo from "@/hooks/useUserInfo";
import { useLocalContext } from "@/setup/locale-provider";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import Image from "next/image";
import { useRouter } from "next/router";
import logo from "public/logo.png";
import Language from "./Language";
// import Language from "./Language";

export default function DrawerMenu({ opened, setOpened }) {
  const { getLabel } = useLocalContext();
  const trans = useTrans();
  const { userInfo, isLogin } = useUserInfo();
  const { logout } = useAuth();
  const theme = useMantineTheme();
  const {asPath} = useRouter();

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Link scroll={false} href="/" passHref>
          <a>
            <Box onClick={() => setOpened(false)}>
            <Image src={logo} alt="logo" width={64} height={40} />

            </Box>
          </a>
        </Link>
      }
      padding="sm"
      size="md"
    >
      <NavLink
        label={<TextInput size="sm" placeholder={getLabel("Tìm kiếm...")} />}
      />
      {isLogin && (
        <>
          <NavLink
            label={<Text size="md">{userInfo?.name}</Text>}
            icon={
              <Avatar
                src={appendImageUrlFromAPI({
                  src: userInfo?.picture,
                })}
                alt={userInfo?.name}
                radius="xl"
                size="sm"
              />
            }
          >
            <NavLink
              label={
                <Link scroll={false} href="/user-profile/my-profile">
                  <a>
                    <Text 
                      size="md" 
                      transform="uppercase" 
                      color={asPath==="/user-profile/my-profile"?theme.colors.second[3]:theme.colors.second[0]}
                      onClick={() => setOpened(false)}
                    >
                      {getLabel("Hồ sơ")}
                    </Text>
                  </a>
                </Link>
              }
            />
            <NavLink
              label={
                <Link scroll={false} href="/user-profile/address-book">
                  <a>
                    <Text 
                      size="md" 
                      transform="uppercase" 
                      color={asPath==="/user-profile/address-book"?theme.colors.second[3]:theme.colors.second[0]}
                      onClick={() => setOpened(false)}
                    >
                      {getLabel("Sổ địa chỉ")}
                    </Text>
                  </a>
                </Link>
              }
            />
            <NavLink
              label={
                <Link scroll={false} href="/user-profile/purchased-return">
                  <a>
                    <Text 
                      size="md" 
                      transform="uppercase" 
                      color={asPath==="/user-profile/purchased-return"?theme.colors.second[3]:theme.colors.second[0]}
                      onClick={() => setOpened(false)}
                    >
                      {getLabel("Đơn hàng")}
                    </Text>
                  </a>
                </Link>
              }
            />
            <NavLink
              label={
                <Link scroll={false} href="/user-profile/liked-product">
                  <a>
                    <Text 
                      size="md" 
                      transform="uppercase" 
                      color={asPath==="/user-profile/liked-product"?theme.colors.second[3]:theme.colors.second[0]}
                      onClick={() => setOpened(false)}
                    >
                      {getLabel("Sản phẩm yêu thích")}
                    </Text>
                  </a>
                </Link>
              }
            />
          </NavLink>
          <Divider />
        </>
      )}
      <NavLink
        label={
          <Link scroll={false} href="/gioi-thieu" passHref>
            <a>
              <Text
                size="md"
                transform="uppercase"
                // color={theme.colors.second[0]}
                onClick={() => setOpened(false)}
                color={asPath==="/gioi-thieu"?theme.colors.second[3]:theme.colors.second[0]}
              >
                {trans?.menu.about.title}
              </Text>
            </a>
          </Link>
        }
      />
      <NavLink
        label={
          <Link scroll={false} href="/mua-hang/san-pham?sort=newest" passHref>
            <a>
              <Text
                size="md"
                transform="uppercase"
                color={asPath.search("mua-hang")>=0 && asPath.search("promotion")<0?theme.colors.second[3]:theme.colors.second[0]}
                onClick={() => setOpened(false)}
              >
                {trans?.menu.products.title}
              </Text>
            </a>
          </Link>
        }
      />
      <NavLink
        label={
          <Link scroll={false} href="/mua-hang/san-pham?filterBy=promotion" passHref>
            <a>
              <Text
                size="md"
                transform="uppercase"
                color={asPath.search("promotion")>=0?theme.colors.second[3]:theme.colors.second[0]}
                onClick={() => setOpened(false)}
              >
                {trans?.menu.promotion.title}
              </Text>
            </a>
          </Link>
        }
      />
      <NavLink
        label={
          <Link scroll={false} href="/tin-tuc" passHref>
            <a>
              <Text
                size="md"
                transform="uppercase"
                color={asPath==="/tin-tuc"?theme.colors.second[3]:theme.colors.second[0]}
                onClick={() => setOpened(false)}
              >
                {trans?.menu.news.title}
              </Text>
            </a>
          </Link>
        }
      />
      <NavLink
        label={
          <Link scroll={false} href="/lien-he" passHref>
            <a>
              <Text
                size="md"
                transform="uppercase"
                color={asPath==="/lien-he"?theme.colors.second[3]:theme.colors.second[0]}
                onClick={() => setOpened(false)}
              >
                {trans?.menu.contact.title}
              </Text>
            </a>
          </Link>
        }
      />
      <Divider />
      <NavLink
        label={
          isLogin ? (
            <Box onClick={() => logout(userInfo?.token)}>
              <Text size="md" transform="uppercase" color={theme.colors.second[0]}>
                {trans?.profile.manager.logout}
              </Text>
            </Box>
          ) : (
            <Box display="flex">
              <Box>
                <Link scroll={false} href="/dang-nhap">
                  <a>
                  <Text size="md" transform="uppercase" color={theme.colors.second[0]} onClick={() => setOpened(false)}>
                    {trans?.profile.manager.login}
                  </Text>
                  </a>
                </Link>
              </Box>
              <Box mx="xs">/</Box>
              <Box>
                <Link scroll={false} href="/dang-ky">
                  <a>
                    <Text size="md" transform="uppercase" color={theme.colors.second[0]} onClick={() => setOpened(false)}>
                      {trans?.profile.manager.register}
                    </Text>
                    </a>
                </Link>
              </Box>
            </Box>
          )
        }
      />
      <NavLink label={<Language />} />
    </Drawer>
  );
}
