import { flattenObj } from "@/utils/lodash";

const sort = {
  date_created: -1,
};

const newsKeys = {
  all: [{ scope: "ecompages", limit: 100, sort }],
  count: (options, locale = "vi") => [
    {
      scope: "ecompages",
      type: "count",
      locale,
      ...flattenObj(options || {}),
    },
  ],
  list: (options, locale = "vi") => [
    {
      scope: "ecompages",
      type: "list",
      sort,
      locale,
      ...flattenObj(options || {}),
    },
  ],

  detail: (_id, locale = "vi") => [{ scope: "ecompages", type: "detail", _id, locale }],
};

export default newsKeys;
