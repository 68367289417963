// import { flattenObj } from "@/utils/lodash";

// const sort = {
//   date_updated: -1,
// };

const cartKeys = {
  all: [{ scope: "cart" }],
  // count: (options) => [
  //   {
  //     scope: "cart",
  //     type: "count",
  //     ...flattenObj(options || {}),
  //   },
  // ],
  // list: (options) => [
  //   {
  //     scope: "cart",
  //     type: "list",
  //     sort,
  //     ...flattenObj(options || {}),
  //   },
  // ],
  // first: () => [
  //   {
  //     scope: "cart",
  //     type: "list",
  //     sort,
  //   },
  // ],
  detail: (userToken) => [{ scope: "cart", type: "detail", token: userToken }],
};

export default cartKeys;
