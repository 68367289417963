import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Input,
  List,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import Link from "next/link";
// import Link from "next/link";
import { AiFillBank } from "@react-icons/all-files/ai/AiFillBank";
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { AiFillInstagram } from "@react-icons/all-files/ai/AiFillInstagram";
import { AiFillMail } from "@react-icons/all-files/ai/AiFillMail";
import { AiFillPhone } from "@react-icons/all-files/ai/AiFillPhone";
import { AiFillWallet } from "@react-icons/all-files/ai/AiFillWallet";
import { AiFillYoutube } from "@react-icons/all-files/ai/AiFillYoutube";
import { FaHandHoldingUsd } from "@react-icons/all-files/fa/FaHandHoldingUsd";
import { useRouter } from "next/router";

import { useForm } from "@mantine/form";
// import Image from "next/image";
// import logo from "public/logo_white.png";
import {
  useFetchAppInfo,
  useFetchProductLine,
} from "@/apis/queryFunctions/appInfo";
import {
  useFetchEcompage,
  useFetchEcompages,
} from "@/apis/queryFunctions/ecompagesFunctions";
import { postSubscribe } from "@/apis/queryFunctions/subscribe";
import useTrans from "@/hooks/useTrans";
import { appAlert } from "@/setup/mantine-provider/notifications";
import sanitizeDOMData from "@/utils/sanitizeDOMData";
import Image from "next/image";
import bg from "public/footer/subscribe_bg.jpg";
import logo from "public/logo.png";
import Container from "../Container/Container";
import content from "./content.json";
import useStyles from "./styles";

export default function Footer() {
  const trans = useTrans();
  const { classes } = useStyles();
  const { locale } = useRouter();
  const theme = useMantineTheme();

  // const menu = content[locale].menu;

  const { data: appInfo } = useFetchAppInfo({});

  const { data: productLines } = useFetchProductLine();

  const { data: huongDan } = useFetchEcompages({
    sort: { sst: 1 },
    limit: 5,
    condition: {
      ngon_ngu: locale,
      loai_tin_tuc: "Hướng dẫn",
      shared: true,
    },
  });
  const { data: cskh } = useFetchEcompages({
    sort: { sst: 1 },
    limit: 5,
    condition: {
      ngon_ngu: locale,
      loai_tin_tuc: "Chính sách khách hàng",
      shared: true,
    },
  });

  const linkPttt = cskh?.find((item) => item?.slug === "chinh-sach-mua-hang");
  const { data: lienHe } = useFetchEcompage("trang-lien-he", locale);

  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (value.length > 0 ? null : true),
    },
  });

  const contactMutation = useMutation(postSubscribe, {
    onSuccess: (rs) => {
      if (rs._id) {
        appAlert({
          type: "success",
          message: trans?.subscribe.alertSuccess,
        });
      } else {
        appAlert({ message: trans?.subscribe.alertFailed });
      }
      form.reset();
    },
  });

  const handleSubmit = () => {
    contactMutation.mutate({ ...form.values });
  };

  return (
    <div className={classes.container}>
      <div className={classes.subscribe}>
        <div className={classes.bgSubscribe}>
          <Image src={bg} alt="bg subscribe" layout="fill" />
        </div>
        <div className={classes.subscribeContent}>
          <Title order={4} mb={5}>
            <Text
              weight="normal"
              color={theme.colors.dark[9]}
              sx={{ letterSpacing: 2.5 }}
              align="center"
            >
              {trans?.subscribe.title}
            </Text>
          </Title>
          {/* <Text size="sm" color={theme.colors.second[0]} align="center" mb={30}>
            {sanitizeDOMData(subs.text)}
          </Text> */}
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Group spacing={0}>
              <Input.Wrapper>
                <TextInput
                  placeholder={trans?.subscribe.placeholder}
                  radius="md"
                  styles={{
                    input: {
                      borderEndEndRadius: 0,
                      borderTopRightRadius: 0,
                    },
                  }}
                  {...form.getInputProps("email")}
                />
              </Input.Wrapper>
              <Button
                type="submit"
                color="dark"
                radius="md"
                styles={{
                  root: {
                    borderTopLeftRadius: 0,
                    borderEndStartRadius: 0,
                  },
                }}
              >
                <Text weight="normal">{trans?.subscribe.button}</Text>
              </Button>
            </Group>
          </form>
        </div>
      </div>
      <div className={classes.info}>
        <Container sx={{ width: "100%" }}>
          <div className={classes.infoBody}>
            <Grid>
              <Grid.Col xs={12} sm={6} md={3}>
                <div className={classes.logoWrapper}>
                  <Link scroll={false} href="/">
                    <a>
                      <div className={classes.logo}>
                        <Image
                          src={logo}
                          alt="Việt Thiên"
                          priority
                          // layout="responsive"
                          width={80}
                          height={50}
                        />
                      </div>
                    </a>
                  </Link>
                </div>
                <InfoTitle>
                  {locale === "vi" ? appInfo?.name : appInfo?.name_en}
                </InfoTitle>
                <InfoText className={classes.infoText1}>
                  {trans?.footer.addressLabel}:{" "}
                  {locale === "vi" ? appInfo?.address : appInfo?.address_en}
                </InfoText>
                <Box
                  style={{
                    position: "relative",
                    aspectRatio: "2.64",
                    width: "70%",
                  }}
                >
                  <Link
                    href="http://online.gov.vn/Home/WebDetails/104069"
                    passHref
                  >
                    <a target="_blank">
                      <Image
                        alt=""
                        src="http://online.gov.vn/Content/EndUser/LogoCCDVSaleNoti/logoSaleNoti.png"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </Box>
                {/* <br />
                <InfoTitle>Hotline</InfoTitle>
                <Box
                  sx={{
                    background: "#696969",
                    borderRadius: 8,
                    padding: "10px 12px",
                  }}
                >
                  <Text weight={400} size={13} color="#fff">
                    Giải đáp thắc mắc và khiếu nại
                  </Text>
                  {appInfo?.phone && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AiFillPhone style={{ marginRight: 5 }} />
                      <Text size="sm">
                        {appInfo?.phone ? `${appInfo?.phone}` : ""}
                      </Text>
                    </Box>
                  )}
                  {appInfo?.email && (
                    <Link href={`mailto:${appInfo?.email}`} passHref>
                      <a>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <AiFillMail style={{ marginRight: 5 }} />
                          <Text size="sm">
                            {appInfo?.email ? `${appInfo?.email}` : ""}
                          </Text>
                        </Box>
                      </a>
                    </Link>
                  )}
                </Box> */}
              </Grid.Col>

              <Grid.Col xs={12} sm={6} md={3}>
                <InfoTitle>{trans?.footer.customerPolicy}</InfoTitle>
                {cskh &&
                  cskh.map((item, k) => (
                    <Link
                      scroll={false}
                      href={`/chinh-sach/${item?.slug}`}
                      key={k}
                    >
                      <a>
                        <InfoText className={classes.infoText}>
                          {item?.title}
                        </InfoText>
                      </a>
                    </Link>
                  ))}
                <br />
                {Boolean(huongDan?.length) && <InfoTitle>Hướng dẫn</InfoTitle>}
                {huongDan &&
                  Boolean(huongDan?.length) &&
                  huongDan?.map((item, k) => (
                    <Link
                      scroll={false}
                      href={`/huong-dan/${item?.slug}`}
                      key={k}
                    >
                      <a>
                        <InfoText className={classes.infoText}>
                          {item?.title}
                        </InfoText>
                      </a>
                    </Link>
                  ))}
              </Grid.Col>

              <Grid.Col xs={12} sm={6} md={3}>
                <InfoTitle>{trans?.footer.contactInfo}</InfoTitle>
                {lienHe?.add_on_data?.map((item, index) => (
                  <InfoText className={classes.infoText1} key={index}>
                    {`${item?.title}: ${item?.summary}`}
                  </InfoText>
                ))}
                {/* <InfoText className={classes.infoText1}>
                  Nhà máy 2: 60-62 Tô Vĩnh Diện , TP Bảo Lộc, Tỉnh Lâm Đồng
                </InfoText> */}
                <InfoText className={classes.infoText1}>
                  {productLines?.length > 0
                    ? `Hotline: ${productLines[0]?.phone}`
                    : ""}
                </InfoText>
                <InfoText className={classes.infoText1}>
                  {appInfo?.email ? `Email: ${appInfo.email}` : ""}
                </InfoText>
              </Grid.Col>

              <Grid.Col xs={12} sm={6} md={3}>
                <InfoTitle>{trans?.footer.followUs}</InfoTitle>
                <Group style={{ fontSize: 24 }}>
                  {productLines?.length > 0 && (
                    <Link scroll={false} href={productLines[0].facebook}>
                      <a target="_blank" aria-label="Facebook">
                        <AiFillFacebook />
                      </a>
                    </Link>
                  )}
                  {appInfo?.instagram && (
                    <Link scroll={false} href={appInfo?.instagram}>
                      <a target="_blank" aria-label="Instagram">
                        <AiFillInstagram />
                      </a>
                    </Link>
                  )}
                  {appInfo?.youtube && (
                    <Link scroll={false} href={appInfo?.youtube}>
                      <a target="_blank" aria-label="Youtube">
                        <AiFillYoutube />
                      </a>
                    </Link>
                  )}
                  {appInfo?.email && (
                    <Link scroll={false} href={`mailto:${appInfo?.email}`}>
                      <a target="_blank" aria-label="Email">
                        <AiFillMail />
                      </a>
                    </Link>
                  )}
                </Group>
                <br />
                <InfoTitle>{trans?.footer.payments}</InfoTitle>
                <List spacing="xs" size="sm" center sx={{ color: "#fff" }}>
                  <List.Item icon={<FaHandHoldingUsd />}>
                    <Link href={`/chinh-sach/${linkPttt?.slug}`}>
                      {trans?.footer.cod}
                    </Link>
                  </List.Item>
                  <List.Item icon={<AiFillBank />}>
                    <Link href={`/chinh-sach/${linkPttt?.slug}`}>
                      {trans?.footer.bankTransfer}
                    </Link>
                  </List.Item>
                  <List.Item icon={<AiFillWallet />}>
                    <Link href={`/chinh-sach/${linkPttt?.slug}`}>
                      {trans?.footer.wallet}
                    </Link>
                  </List.Item>
                </List>
                
              </Grid.Col>
            </Grid>
          </div>
          <Divider my="sm" />
          <Box mb="sm">
            <Text size="sm" weight="normal">
              {trans?.footer.info1}
            </Text>
            {/* <InfoText>{trans?.footer.info2}</InfoText> */}
            <Text size="sm" weight="normal">
              {trans?.footer.info2}
            </Text>
          </Box>
          {/* <Divider color="#444" />
          <div className={classes.infoBottom}>
            <Text>Mã số thuế: {appInfo?.ma_so_thue || "0316964018"}</Text>
            <br />
            <Text>© Bản quyền Giày Công Sở Fasmono</Text>
          </div> */}
        </Container>
      </div>
    </div>
  );
}

function InfoTitle({ children }) {
  return (
    <Text size="sm" transform="uppercase" weight="500" mb={12}>
      {children}
    </Text>
  );
}

function InfoText({ children, className }) {
  return (
    <Text size="sm" weight="normal" mb={4} className={className}>
      {children}
    </Text>
  );
}
