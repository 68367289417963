import { Container as PageContainer } from "@mantine/core";
import React from "react";

export default function Container({ children, fluid = false, ...style }) {
  if (fluid) {
    return (
      <PageContainer size={1440} {...style}>
        {children}
      </PageContainer>
    );
  }
  return (
    <PageContainer size="lg" {...style}>
      {children}
    </PageContainer>
  );
}
