import { useQuery } from "@tanstack/react-query";
import { asyncGetItem, asyncGetList } from "../fetch";
import { categoryKeys } from "../queryKeys";
// import initialCategories from "./initialData/categories.json";

const DEFAULT_STALE_TIME = 1000 * 60 * 60; // 1 hour
// const DEFAULT_STALE_TIME = 1000; // 5 sec

export const fetchCategory = async (slug) =>
  asyncGetItem({
    collection_name: "ecomcategories_cty",
    options: {
      condition: {
        ma_nganh_hang:process.env.ma_nganh_hang,
        slug,
        id_app: process.env.id_app,
      },
    },
  });

export const useFetchCategory = (slug) =>
  useQuery(categoryKeys.detail(slug), () => fetchCategory(slug), {
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!slug,
  });

/* -------------------------------------------------------------------------- */

export const fetchCategories = async (options = {}) =>
  asyncGetList({
    collection_name: "ecomcategories_cty",
    options: {
      ...options,
      condition: {
        ma_nganh_hang:process.env.ma_nganh_hang,
        ...(options?.condition || {}),
      },
    },
  })



export const useFetchCategories = (options = {}) => useQuery(
  categoryKeys.list(options),
  () => fetchCategories(options),
  {
    enabled: Boolean(options),
    // staleTime: DEFAULT_STALE_TIME,
    // initialData: initialCategories,
    // onSuccess: (categories) => {
    //   categories.map((category) => {
    //     queryClient.setQueriesData(
    //       categoryKeys.detail(category?.slug),
    //       category
    //     );
    //   });
    // },
  })
// const queryClient = useQueryClient();

export const useFetchCategoryHighlight = (options = {}) => useQuery(
  categoryKeys.list({ highlight: true, ...options }),
  () => fetchCategories({
    condition: {
      highlight: true,
    },
    ...options,
  }),
  {
    staleTime: DEFAULT_STALE_TIME,

  }
)
