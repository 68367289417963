// import { useFetchCategories } from "@/apis/queryFunctions/categoryFunctions";
import { CartContext } from "@/setup/cartProvider";
import { getFromStorage, removeStorage } from "@/utils/localStorage";
import { Affix, LoadingOverlay, Overlay, createStyles } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import ScrollToTop from "../components/ScrollToTop";
import Footer from "./Footer";
import Header from "./Header";

const useStyles = createStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "hidden",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {},
  },
  sidenavWrapper: {
    width: "100%",
    minHeight: "100px",
    position: "fixed",
    top: "0px",
    left: "0px",

    display: "flex",
    zIndex: 9999,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      minHeight: "50px",
    },
  },
  section: {
    minHeight: "calc(100vh - 100px)",
    minWidth: "100%",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "100px",
    overflow: "hidden",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      minHeight: "calc(100vh - 50px)",
      marginTop: "50px",
    },
  },
  body: {
    minHeight: "calc(100vh - 125px)",
    flex: 1,
    flexDirection: "column",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      minHeight: "calc(100vh - 85px)",
    },

    // [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    //   marginLeft: 0,
    //   marginTop: "80px",
    // },
  },
}));

export default function Layout({ children }) {
  const {asPath} = useRouter();
  // console.log('finding', asPath)

  const { id_afflink, setId_afflink } = useContext(CartContext);

  const getAfflinkValue = () => {
    const queryParams = new URLSearchParams(asPath.split('?')[1]);
    return queryParams.get('afflink');
  };

  const afflinkValue0 = getAfflinkValue();

  useEffect(() => {
    if (afflinkValue0 !== null) {
      setId_afflink(afflinkValue0);
      sessionStorage.setItem('afflinkValue', afflinkValue0);
    }
  }, [afflinkValue0, setId_afflink]);

  useEffect(() => {
    const storedAfflinkValue = sessionStorage.getItem('afflinkValue');
    if (storedAfflinkValue !== null) {
      setId_afflink(storedAfflinkValue);
    }
  }, [setId_afflink]);
  const { classes } = useStyles();
  const { pathname, reload } = useRouter();
  const { ref: headerRef, height: headerHeight } = useElementSize();
  const { ref, height } = useElementSize();
  const [visible, setVisible] = React.useState(false);

  // useFetchCategories();
  function reveal() {
    const reveals = document.querySelectorAll(".reveal");

    for (let i = 0; i < reveals.length; i+=1) {
      const windowHeight = window.innerHeight;
      const elementTop = reveals[i].getBoundingClientRect().top;
      const elementVisible = 100;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  React.useEffect(() => {
    window.addEventListener("scroll", reveal);

    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

  React.useEffect(() => {
    function init() {
      const section = document.querySelectorAll("section");
      for (let i = 0; i < section.length; i+=1) {
        section[i].firstChild.classList.add("reveal");
      }
    }
    init();
    const start = window.setTimeout(reveal, 1000);

    return () => {
      window.clearTimeout(start);
    };
  }, [pathname]);

  React.useEffect(() => {
    const needReload = Boolean(getFromStorage("updateInfo"));
    if (needReload) {
      reload();
      removeStorage("updateInfo");
    }
  }, [pathname]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <main className={classes.main}>
        {/* <div className={classes.sidenavWrapper} ref={headerRef}>
          <Header setOverlay={setVisible} />
        </div> */}
        <Affix position={{ top: "0px", left: "0px" }}>
          <div className={classes.sidenavWrapper} ref={headerRef}>
            <Header setOverlay={setVisible} />
          </div>
        </Affix>

        <div className={classes.section} ref={ref}>
          <div className={classes.body}>
            {visible && (
              <Overlay
                opacity={0.6}
                color="#000"
                zIndex={10}
                style={{ height: `calc(${height}px + ${headerHeight}px)` }}
              />
            )}
            {children}
          </div>

          <Footer />
        </div>

        <ScrollToTop />
      </main>
    </>
  );
}
