import {
  Transition as ReactTransition,
  TransitionGroup,
} from "react-transition-group";

const TIMEOUT = 200;

const getTransitionStyles = {
  entering: {
    // position: `absolute`, // BLUG: blinking to ramdom place
    opacity: 0,
    transform: `translateY(50px)`,
  },
  entered: {
    transition: `opacity ${TIMEOUT}ms ease-in-out, transform ${TIMEOUT}ms ease-in-out`,
    opacity: 1,
    transform: `translateX(0px)`,
    animation: "blink .3s linear 2",
  },
  exiting: {
    transition: `opacity ${TIMEOUT}ms ease-in-out, transform ${TIMEOUT}ms ease-in-out`,
    opacity: 0,
    transform: `translateY(-50px)`,
  },
};

function Transition({ children, location }) {
  const excludedPaths = ["mua-hang"];
  const isOnExludedPaths = excludedPaths.some((path) =>
    location.includes(path)
  );

  return (
    <TransitionGroup style={{ position: "relative" }}>
      <ReactTransition
        key={location}
        timeout={{
          enter: TIMEOUT,
          exit: TIMEOUT,
        }}
        onEntered={() => window.scrollTo(0, 0)}
      >
        {(status) => (
          <div
            style={isOnExludedPaths ? {} : { ...getTransitionStyles[status] }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
}
export default Transition;
