import { Box, Button, Group, Modal, Text } from "@mantine/core";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useRef } from "react";

const DialogAction = forwardRef((props, ref) => {
  const [titleDialog, setTitleDialog] = React.useState("");
  const [isShow, setIsShow] = React.useState(false);
  const [contentDialog, setContentDialog] = React.useState("");
  const onSubmitRef = useRef();
  const { locale } = useRouter();
  const onCancelRef = useRef();
  useImperativeHandle(ref, () => ({
    handleClick: (title, message, onConfirm, onCancel) => {
      setIsShow(true);
      setTitleDialog(title);
      setContentDialog(message);
      onSubmitRef.current = onConfirm;
      onCancelRef.current = onCancel;
    },
  }));

  const hideDialog = (event) => {
    // if (reason && reason == "backdropClick") onCancelRef.current();
    setIsShow(!isShow);
  };

  const onSubmit = () => {
    if (onSubmitRef.current) {
      onSubmitRef.current();
    }
    setIsShow(false);
  };

  return (
    <div>
      <Modal
        centered
        // fullWidth={true}
        opened={isShow}
        onClose={hideDialog}
        title={titleDialog || locale === "vi" ? "Thông báo" : "Notify"}
        styles={(theme) => ({
          title: {
            fontWeight: "bold",
            fontSize: 20,
          },
          header: {
            marginBottom: 10,
          },
        })}
      >
        <Text>{contentDialog}</Text>

        {typeof onCancelRef?.current === "function" && (
          <Button
            variant="outlined"
            onClick={() => {
              onCancelRef.current();
              setIsShow(false);
            }}
            color="primary"
          >
            Hủy
          </Button>
        )}

        <Box sx={{ flex: 1, justifyContent: "flex-end", display: "flex" }}>
          <Button variant="contained" onClick={onSubmit} color="orange">
            Xác nhận
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

DialogAction.displayName = "DialogAction";

export default DialogAction;

DialogAction.propTypes = {
  isShow: PropTypes.bool,
  setIsShow: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
};
