import { useQuery, useQueryClient } from "@tanstack/react-query";
import { asyncGet, asyncGetList } from "../fetch";

const DEFAULT_STALE_TIME = 60000; // 1min

export const fetchAppInfo = async () => {
  const url = `${process.env.server_url_report}/api/app/${process.env.id_app}?access_token=${process.env.public_token}`;
  return await asyncGet(url);
};

export const useFetchAppInfo = ({ options = {} }) =>
  useQuery(["appInfo"], () => fetchAppInfo({ options }), {
    staleTime: DEFAULT_STALE_TIME,
  });

export const fetProductLines = async () =>
  asyncGetList({
    collection_name: "ecomproductlines",
    options: {
      page: 1,
      limit: 100,
      condition: {
        ma_nganh_hang: "TRA",
      },
    },
  });

export const useFetchProductLine = () =>
  useQuery(["ecomproductlines"], () => fetProductLines(), {
    staleTime: DEFAULT_STALE_TIME,
  });
