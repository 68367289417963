import useUserInfo from "@/hooks/useUserInfo";
import { config } from "@/setup/cartProvider";
import { useApiContext } from "@/setup/flexbiz-api-provider";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { asyncGetItem } from "../fetch";

export const fetchOrder = async ({ userInfo }) => {
  const data = await asyncGetItem({
    access_token: userInfo.token,
    collection_name: "so1",
    options: {
      condition: {
        trang_thai: "LCT",
        user_created: userInfo?.email,
      },
    },
  });

  if (!data) return [];

  if (data && data.length <= 1) {
    return data;
  }
  return data;
};

export const useFetchOrder = () => {
  const { userInfo } = useApiContext();
  const { isLogin } = useUserInfo();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (isLogin !== false && userInfo?.token) {
      setInfo({ token: userInfo.token, email: userInfo.email });
    } else {
      setInfo({ token: config.public_token, email: "public" });
    }
  }, [userInfo, isLogin]);

  return useQuery(["so1", info], () => fetchOrder({ userInfo: info }), {
    // staleTime: process.env.DEFAULT_STALE_TIME,
    enabled: !!info?.token,
  });
};
