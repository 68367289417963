// import { getColorCode } from "@/utils/colorMapping";
import {
  ActionIcon,
  Badge,
  createStyles,
  Grid,
  Group,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import Image from "next/image";
import React from "react";

import useTrans from "@/hooks/useTrans";
import { QuantityInput } from "@/pages/cart/components/QuantityChange";
import { useCartContext } from "@/setup/cartProvider";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { RiDeleteBin6Line } from "@react-icons/all-files/ri/RiDeleteBin6Line";
import { useRouter } from "next/router";

const useStyles = createStyles(() => ({
  imageWrapper: {
    position: "relative",
    aspectRatio: "1",
    width: "100%",
  },
}));

export default function ItemCart({ item }) {
  const { classes } = useStyles();
  const trans = useTrans();
  const context = useCartContext();
  const { locale } = useRouter();
  const deleteCart = async (product) => {
    try {
      await context?.deleteCart(product);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid>
      <Grid.Col xs={4}>
        <Stack spacing={5}>
          <div className={classes.imageWrapper}>
            <Image
              src={appendImageUrlFromAPI({
                src: item.picture_tt || item.picture,
                size: "l",
              })}
              alt="a"
              layout="fill"
            />
          </div>
          {!item?.ton && (
            <Badge color="teal" size="xs">
              <Text weight="normal">{trans.miniCart.statusStock}</Text>
            </Badge>
          )}
        </Stack>
      </Grid.Col>
      <Grid.Col xs={8}>
        <Stack spacing={5}>
          {item?.tien_ck > 0 && (
            <Badge
              size="xs"
              fullWidth
              sx={{ width: "fit-content" }}
              color="red"
            >
              Khuyến mãi
            </Badge>
          )}
          <Text size="xs" weight="700" lineClamp={3}>
            {locale === "vi" ? item?.ten_vt : item?.ten_vt_eng}
            {/* Giày Cao Gót SlingBack Phối Nơ */}
          </Text>
          <Group>
            <Text color="dark">
              VND {(item?.tien / item?.sl_xuat).toLocaleString()}
            </Text>
            {/* <Badge color="red" variant="filled" size="xs">
              <Text size={"xs"} weight="500">
                {(item?.tien / item?.sl_xuat).toLocaleString()}đ
              </Text>
            </Badge> */}
            {item?.tien_ck && (
              <Text strikethrough>{item?.gia_ban_le.toLocaleString()}</Text>
            )}
          </Group>
          {item?.thuoc_tinh && (
            <Group>
              {item?.thuoc_tinh?.gia_tri_bien_the_2 && (
                <Text>
                  Kích thước: <b>{item?.thuoc_tinh?.gia_tri_bien_the_2}</b>
                </Text>
              )}
              {item?.thuoc_tinh?.gia_tri_bien_the_1 && (
                <Tooltip label={item?.thuoc_tinh?.gia_tri_bien_the_1}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Text mr={4}>
                      Màu sắc: {item?.thuoc_tinh?.gia_tri_bien_the_1}
                    </Text>
                    {/* <span
                      style={{
                        width: 25,
                        height: 10,
                        borderRadius: 5,
                        background: getColorCode(
                          item?.thuoc_tinh?.gia_tri_bien_the_1
                        ),
                      }}
                    ></span> */}
                  </div>
                </Tooltip>
              )}
            </Group>
          )}
          <Grid align="center">
            <Grid.Col xs={8}>
              <QuantityInput item={item} />
            </Grid.Col>
            {/* <Grid.Col xs={4}>
              <Text>Số lượng:</Text>
            </Grid.Col>
            <Grid.Col xs={4}>
              <NumberInput
                defaultValue={item?.sl_xuat}
                min={item?.sl_xuat}
                max={item?.sl_xuat}
                size="xs"
                style={{
                  width: "50px",
                }}
                styles={{ input: { textAlign: "center !important" } }}
                hideControls
                // disabled
              />
            </Grid.Col> */}
            <Grid.Col xs={4}>
              <ActionIcon
                style={{ cursor: "pointer" }}
                onClick={() => deleteCart(item)}
              >
                <RiDeleteBin6Line />
              </ActionIcon>
            </Grid.Col>
          </Grid>
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
