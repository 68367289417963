import { flattenObj } from "@/utils/lodash";

const sort = {
  date_created: -1,
};

const productKeys = {
  all: [{ scope: "dmvt", limit: 20, sort }],
  count: (options) => [
    {
      scope: "dmvt",
      type: "count",
      ...flattenObj(options?.condition || {}),
    },
  ],
  list: (options) => [
    {
      scope: "dmvt",
      type: "list",
      // ...flattenObj(sort),
      ...flattenObj({ sort, ...(options || {}) }),
    },
  ],
  homeList: (ma_danh_muc_cty) => [
    {
      scope: "dmvt",
      type: "bestsellers",
      ma_danh_muc_cty,
    },
  ],
  detail: (ma_vt) => [{ scope: "dmvt", type: "detail", ma_vt }],
};

export default productKeys;
