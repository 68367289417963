import useTrans from "@/hooks/useTrans";
import { TextInput } from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";

export default function SearchBar() {
  const trans = useTrans();
  const [searchTerms, setSearchTerms] = React.useState("");
  const { push, query } = useRouter();

  const handleUpdate = (e) => {
    setSearchTerms(e.target.value);
  };

  React.useEffect(() => {
    setSearchTerms("");
  }, [query]);

  const checkKeyPress = (e) => {
    const { keyCode } = e;

    if (keyCode === 13) {
      push(`/mua-hang/tim-kiem?keyword=${searchTerms}`);
      
    }
  };

  return (
    <TextInput
      size="xs"
      value={searchTerms}
      onChange={handleUpdate}
      onKeyDown={checkKeyPress}
      placeholder={trans?.menu.search.title}
    />
  );
}
