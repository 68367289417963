import useTrans from "@/hooks/useTrans";
import { useCartContext } from "@/setup/cartProvider";
import {
  Button,
  createStyles,
  Divider,
  Group,
  ScrollArea,
  Text,
} from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";
import ItemCart from "./ItemCart";

const useStyles = createStyles(() => ({
  cartBody: {
    padding: "22px 0",
  },
}));

export default function MiniCart({ onClose }) {
  const { classes } = useStyles();
  const trans = useTrans();
  const context = useCartContext();
  // console.log("cart", context);
  const { push } = useRouter();

  const submitOrder = () => {
    onClose();
    context.createOrder({ trang_thai: "LCT" });
  };

  return (
    <div className={classes.cartBody}>
      <Text size="sm">
        {trans.miniCart.titleA}
        <b> {context?.cart?.length || 0} </b>
        {trans.miniCart.titleB}
      </Text>
      {context?.cart?.length > 0 && (
        <>
          <Divider my="sm" />
          <ScrollArea style={{ height: 300 }} offsetScrollbars>
            {/* <div style={{ width: 306 }}> */}
            {context?.cart?.map((item) => (
              <div key={item._id}>
                <ItemCart key={item._id} item={item} />
                <Divider my="sm" />
              </div>
            ))}

            {/* <ItemCart />
            <Divider my="sm" />
            <ItemCart /> */}
            {/* </div> */}
          </ScrollArea>
          <Divider my="sm" />
          <Group position="apart">
            <Text color="black" size="md" weight="500">
              {trans.miniCart.labelTotal}
            </Text>
            <Text color="black" size="md" weight="500">
              VND {context?.infoOrder?.tempPrice?.toLocaleString()}
            </Text>
          </Group>
          <Divider my="sm" />
          <Group position="center">
            <Button
              variant="outline"
              color="dark"
              uppercase
              sx={{ fontWeight: "normal", minWidth: 125 }}
              onClick={() => {
                onClose();
                return push("/cart");
              }}
            >
              {trans.miniCart.btnCart}
            </Button>
            <Button
              color="teal"
              uppercase
              sx={{ fontWeight: "normal" }}
              onClick={submitOrder}
            >
              {trans.miniCart.btnPay}
            </Button>
          </Group>
        </>
      )}
    </div>
  );
}
