import { Affix } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import Image from "next/image";
import top from "public/images/scroll_to_top.png";
import React from "react";

export default function ScrollToTop() {
  const [scroll, scrollTo] = useWindowScroll();

  return scroll.y > 400 ? (
    // <div
    //   style={{
    //     position: "fixed",
    //     bottom: "74px",
    //     right: "24px",
    //     padding: "10px",
    //     cursor: "pointer",
    //     // width: "20px",
    //     // height: "20px",
    //   }}
    //   onClick={() => scrollTo({ y: 0 })}
    // >
    //   <Image src={top} alt="top" width={40} height={40} />
    //   {/* <FiNavigation2 color="blue" size={"30px"} /> */}
    // </div>
    <Affix position={{ bottom: "74px", right: "24px" }} zIndex={100}>
      <div onClick={() => scrollTo({ y: 0 })} style={{ cursor: "pointer" }}>
        <Image src={top} alt="top" width={40} height={40} />
      </div>
    </Affix>
  ) : null;
}
