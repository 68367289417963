const title = "Trà Việt Thiên";
const description = "";

const defaultSEOConfigs = {
  titleTemplate: "%s | Nông Sản Việt Thiên",
  defaultTitle: title,
  title,
  //
  description,
  openGraph: {
    type: "website",
    url: "https://travietthien.vn/",
    title,
    description,
    locale: "vi",
    site_name: "VietThien",
    images: [
      {
        url: `https://api.flexzen.app/api/615fa1791fc31763c7253668/file/download/64084dd1bfb6a2056c86b4f7?size=S&access_token=5d317ff07b8baef29f3230a69fe9d617`,
        width: 380,
        height: 200,
        alt: "VietThien logo",
      },
    ],
  },
};

export default defaultSEOConfigs;
